/* You can add global styles to this file, and also import other style files */
@use "sass:map";
@import "./theme.scss";
@import "./variables.scss";

// color themes
%error-color-theme {
  background-color: map.get($cwi-colors, error, bg);
  border-color: map.get($cwi-colors, error, border);
  color: map.get($cwi-colors, error, font);

  ::-webkit-scrollbar-thumb {
    background: map.get($cwi-colors, error, font);
  }
}

%info-color-theme {
  background-color: map.get($cwi-colors, info, bg);
  border-color: map.get($cwi-colors, info, border);
  color: map.get($cwi-colors, info, font);

  ::-webkit-scrollbar-thumb {
    background: map.get($cwi-colors, info, font);
  }
}

%succes-color-theme {
  background-color: map.get($cwi-colors, succes, bg);
  border-color: map.get($cwi-colors, succes, border);
  color: map.get($cwi-colors, succes, font);

  ::-webkit-scrollbar-thumb {
    background: map.get($cwi-colors, succes, font);
  }
}

%warning-color-theme {
  background-color: map.get($cwi-colors, warning, bg);
  border-color: map.get($cwi-colors, warning, border);
  color: map.get($cwi-colors, warning, font);

  ::-webkit-scrollbar-thumb {
    background: map.get($cwi-colors, warning, font);
  }
}

// themes
.cwi-mat-primary {
  background-color: map.get($cwi-colors, submission, primary);
  color: map.get($cwi-colors, fonts, dark)
}

.cwi-mat-accent {
  background-color: map.get($cwi-colors, submission, secondary);
  color: map.get($cwi-colors, fonts, light)
}

// content-box
%content-box {
  border: $content-box-border;
  border-radius: $content-box-border-radius;
  max-width: $content-box-max-width;
  margin: $content-box-margin;
  padding: $content-box-padding;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

mat-card.error,
.error-content-box {
  @extend %content-box;
  @extend %error-color-theme;
}

mat-card.info,
.info-content-box {
  @extend %content-box;
  @extend %info-color-theme;
}

mat-card.succes,
.succes-content-box {
  @extend %content-box;
  @extend %succes-color-theme;
}

mat-card.warning,
.warning-content-box {
  @extend %content-box;
  @extend %warning-color-theme;
}

// dialog
%dialog-container {
  padding: 15px;
  border-radius: 1rem;
}

.normal-dialog .mat-dialog-container {
  @extend %dialog-container;
}

.error-dialog .mat-dialog-container {
  @extend %dialog-container;
  @extend %error-color-theme;

  .mat-raised-button {
    @extend %error-color-theme;
  }
}

.info-dialog .mat-dialog-container {
  @extend %dialog-container;
  @extend %info-color-theme;

  .mat-raised-button {
    @extend %info-color-theme;
  }
}

.succes-dialog .mat-dialog-container {
  @extend %dialog-container;
  @extend %succes-color-theme;

  .mat-raised-button {
    @extend %succes-color-theme;
  }
}

.warning-dialog .mat-dialog-container {
  @extend %dialog-container;
  @extend %warning-color-theme;

  .mat-raised-button {
    @extend %warning-color-theme;
  }

  .mat-raised-button {
    @extend %warning-color-theme;
  }
}

mat-icon.icon-sm {
  font-size: calc(map.get($icon, size) - map.get($icon, span));
  height: calc(map.get($icon, size) - map.get($icon, span));
  width: calc(map.get($icon, size) - map.get($icon, span));
}

mat-icon.align-icon-with-text {
  font-size: calc(map.get($icon, size) - map.get($icon, span));
  height: calc(map.get($icon, size) - map.get($icon, span));
  margin-bottom: -4px !important;
  margin-right: 2px;
  width: calc(map.get($icon, size) - map.get($icon, span));
}

mat-icon.icon-lg {
  font-size: calc(map.get($icon, size) + map.get($icon, span));
  height: calc(map.get($icon, size) + map.get($icon, span));
  width: calc(map.get($icon, size) + map.get($icon, span));
}

mat-icon.close::before {
  content: map-get($icon, close)
}

mat-icon.info::before {
  content: map-get($icon, info)
}

mat-icon.succes::before {
  content: map-get($icon, succes)
}

mat-icon.warning {
  color: map.get($cwi-colors, warning, font);
}

// fixes
ul {
  list-style-position: outside;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: map.get($cwi-colors, grey, tertiary);
}

.mat-form-field-appearance-outline[hintlabel]:not([hintlabel=""]) {
  .mat-form-field-wrapper {
    margin-bottom: 1em;

    .mat-form-field-subscript-wrapper {
      @extend .mat-small;
    }
  }
}

.mat-tooltip {
  margin: 5px !important;
  white-space: pre-line !important;
  word-break: break-word !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 2em;
}

.pad-none {
  padding: 0px;

  .mat-dialog-container {
    padding: 0px;
  }
}

// Scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: map.get($cwi-colors, grey, tertiary);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: map.get($cwi-colors, submission, secondary);
  border-radius: 100px;
  border-right: 5px transparent solid;
}

// disabled
.disabled-container {
  opacity: 0.5;
  pointer-events: none;
}