@use "sass:map";
// varibles
$cwi-colors: (
  assurance: (primary: #F56354, secondary: #B2211C, tertiary: #FFD9BF),
  reporting: (primary: #54B84A, secondary: #036300, tertiary: #CFF2D1),
  submission: (primary: #FFB0C7, secondary: #8C124F, tertiary: #FFE5F0),
  others: (primary: #A378D9, secondary: #5930AB, tertiary: #E8EDFF),
  help: (primary: #FFED7D, secondary: #F5D612, tertiary: #FCF5D1),
  grey: (primary: #A8A6A8, secondary: #7D7D7D, tertiary: #D4D1D4),
  blue: (primary: #1C5EED, secondary: #0840A3, tertiary: #D1F0FF),
  fonts: (dark: #303333, light:#FFFFFF),
  info:(bg:#bee5eb, border:#D1F0FF, font:#0840A3),
  succes:(bg:#c3e6cb, border:#CFF2D1, font:#036300),
  warning:(bg:#FCF5D1, border:#FFED7D, font:#F56354),
  error:(bg:#FFD9BF, border:#f5c6cb, font:#F56354),
);

$content-box-border: 1px;
$content-box-border-radius: 5px;
$content-box-margin: 10px;
$content-box-padding: 10px;
$content-box-max-width: 800px;


$icon: (
  size: 24px,
  span: 6px,
  close: 'close',
  error: "cancel",
  info: "info",
  succes: "check_circle",
  warning: "error",
);